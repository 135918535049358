var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.mode]},[_c('LMap',{ref:"map",attrs:{"options":{
            attributionControl: false,
            editable: true,
            preferCanvas: true,
            zoomControl: false,
        },"max-bounds":[
            [-90, -270],
            [90, 270] ],"max-bounds-viscosity":0.75,"max-zoom":_vm.activeLayer.maxZoom || _vm.baseTileOptions.maxZoom,"min-zoom":_vm.activeLayer.minZoom || _vm.baseTileOptions.minZoom},on:{"update:zoom":_vm.zoomUpdated,"locationfound":_vm.onLocationFound,"locationerror":_vm.onLocationError,"mouseup":function($event){return _vm.setShouldFollowActiveTrackerUpdates(false)}}},[(_vm.showLocations)?_c('LocationLayer',{attrs:{"locations":_vm.locationsToDisplay},on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'history')?_c('HistoryLayer',{on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'triphistory')?_c('TripHistoryLayer',{attrs:{"map-zoom":_vm.zoom},on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'history' || _vm.mode === 'triphistory')?_c('HistoryPointLayer'):_vm._e(),(_vm.mode === 'connection-heatmap')?_c('ConnectionHeatmapLayer',{attrs:{"measurements":_vm.heatmapData || []},on:{"alignMap":_vm.alignMap}}):_vm._e(),(_vm.mode === 'editing')?_c('CreateLocationLayer'):_vm._e(),(_vm.showTrackerLayer)?_c('TrackerLayer',{attrs:{"trackers":_vm.trackersToDisplay,"excluded-from-clustering":[_vm.activeTrackerOnMap]},on:{"alignMap":_vm.alignMap,"markerClicked":function($event){return _vm.$router.push({
                    name: 'detail',
                    params: {
                        id: $event,
                    },
                })}}}):_vm._e(),(_vm.showAccuracy)?_c('AccuracyCircle'):_vm._e(),_c('TrackerTraceLayer',{attrs:{"coordinates":_vm.activeTrackerTrace}}),_c('UserLayer'),_c('LControlAttribution',{attrs:{"position":"bottomright","prefix":_vm.appVersion}}),_c('LControlScale',{attrs:{"position":"bottomleft","imperial":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }