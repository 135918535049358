<template>
    <div :class="[mode]">
        <LMap
            ref="map"
            :options="{
                attributionControl: false,
                editable: true,
                preferCanvas: true,
                zoomControl: false,
            }"
            :max-bounds="[
                [-90, -270],
                [90, 270],
            ]"
            :max-bounds-viscosity="0.75"
            :max-zoom="activeLayer.maxZoom || baseTileOptions.maxZoom"
            :min-zoom="activeLayer.minZoom || baseTileOptions.minZoom"
            @update:zoom="zoomUpdated"
            @locationfound="onLocationFound"
            @locationerror="onLocationError"
            @mouseup="setShouldFollowActiveTrackerUpdates(false)"
        >
            <LocationLayer
                v-if="showLocations"
                :locations="locationsToDisplay"
                @alignMap="alignMap"
            />

            <HistoryLayer v-if="mode === 'history'" @alignMap="alignMap" />

            <TripHistoryLayer
                v-if="mode === 'triphistory'"
                :map-zoom="zoom"
                @alignMap="alignMap"
            />

            <HistoryPointLayer
                v-if="mode === 'history' || mode === 'triphistory'"
            />

            <ConnectionHeatmapLayer
                v-if="mode === 'connection-heatmap'"
                :measurements="heatmapData || []"
                @alignMap="alignMap"
            />

            <CreateLocationLayer v-if="mode === 'editing'" />

            <TrackerLayer
                v-if="showTrackerLayer"
                :trackers="trackersToDisplay"
                :excluded-from-clustering="[activeTrackerOnMap]"
                @alignMap="alignMap"
                @markerClicked="
                    $router.push({
                        name: 'detail',
                        params: {
                            id: $event,
                        },
                    })
                "
            />

            <AccuracyCircle v-if="showAccuracy" />

            <TrackerTraceLayer :coordinates="activeTrackerTrace" />

            <UserLayer />

            <LControlAttribution position="bottomright" :prefix="appVersion" />

            <LControlScale position="bottomleft" :imperial="false" />
        </LMap>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'
import { LControlAttribution, LControlScale, LMap } from 'vue2-leaflet'

import AccuracyCircle from './AccuracyCircle'
import ConnectionHeatmapLayer from './ConnectionHeatmapLayer'
import CreateLocationLayer from './CreateLocationLayer'
import HistoryLayer from './HistoryLayer'
import HistoryPointLayer from './HistoryPointLayer'
import LocationLayer from './LocationLayer'
import TrackerLayer from './TrackerLayer'
import TrackerTraceLayer from './TrackerTraceLayer'
import TripHistoryLayer from './TripHistoryLayer'
import UserLayer from './UserLayer'

export default {
    name: 'AxMap',
    components: {
        AccuracyCircle,
        ConnectionHeatmapLayer,
        CreateLocationLayer,
        HistoryLayer,
        HistoryPointLayer,
        LControlAttribution,
        LControlScale,
        LMap,
        LocationLayer,
        TrackerLayer,
        TrackerTraceLayer,
        TripHistoryLayer,
        UserLayer,
    },
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION,
            baseTileOptions: {
                maxZoom: 20,
                minZoom: 2,
            },
            zoom: null,
        }
    },
    computed: {
        ...mapState('location', ['activeLocationOnMap', 'locations']),
        ...mapState('map', [
            'heatmapData',
            'savedBounds',
            'showAccuracy',
            'showLocations',
            'tileProviders',
        ]),
        ...mapState('tracker', [
            'activeTrackerOnMap',
            'activeTrackerTrace',
            'shouldFollowActiveTrackerUpdates',
            'trackers',
        ]),
        ...mapGetters('map', ['activeLayer', 'defaultLayer']),
        trackersToDisplay() {
            return this.trackers.filter(
                tracker =>
                    tracker.asset_details.position.latitude &&
                    tracker.asset_details.position.longitude
            )
        },
        locationsToDisplay() {
            // do not show active location if editing a location
            if (this.mode == 'editing' && this.activeLocationOnMap) {
                return this.locations.filter(
                    location => location.id != this.activeLocationOnMap.id
                )
            }

            return this.locations
        },
        mode() {
            if (
                this.$route.path === '/map/location/create' ||
                this.$route.path.match(/^\/map\/location\/\d*\/edit$/)
            ) {
                return 'editing'
            } else if (
                this.$route.path.match(/^\/map\/assets\/\d*\/location-history$/)
            ) {
                return 'history'
            } else if (
                this.$route.path.match(/^\/map\/assets\/\d*\/trip-history$/)
            ) {
                return 'triphistory'
            } else if (
                this.$route.name === 'connectionHeatmap' ||
                this.$route.name === 'assetConnectionHeatmap' ||
                this.$route.name === 'locationConnectionHeatmap'
            ) {
                return 'connection-heatmap'
            }

            return 'default'
        },
        showTrackerLayer() {
            return !['history', 'connection-heatmap', 'triphistory'].includes(
                this.mode
            )
        },
    },
    watch: {
        activeTrackerTrace(trace) {
            if (this.shouldFollowActiveTrackerUpdates && trace.length > 1) {
                this.$refs.map.mapObject.panTo(trace[0], {
                    animate: true,
                    duration: 1,
                })
            }
        },
    },
    mounted() {
        this.setMapInstance(this.$refs.map.mapObject)

        if (this.activeLayer.layer) {
            this.$refs.map.mapObject.addLayer(this.activeLayer.layer)
        } else {
            this.$refs.map.mapObject.addLayer(this.defaultLayer.layer)
            this.setActiveLayerId(this.defaultLayer.id)
        }

        if (this.savedBounds) {
            this.$refs.map.mapObject.fitBounds(this.savedBounds)
            this.clearSavedBounds()
        } else {
            const bounds = this.trackers
                .filter(item => item.asset_details)
                .map(item => [item.asset_details.lat, item.asset_details.lng])

            this.$refs.map.mapObject.fitBounds(bounds)
        }
    },
    beforeDestroy() {
        this.$refs.map.mapObject.removeLayer(this.activeLayer.layer)
        this.disablePositionTracking()
    },
    methods: {
        ...mapMutations('map', [
            'clearSavedBounds',
            'setActiveLayerId',
            'setMapInstance',
            'setPositionTrackingEnabled',
            'setUserPosition',
        ]),
        ...mapMutations('tracker', ['setShouldFollowActiveTrackerUpdates']),
        areCoordinatesVisible(coordinates) {
            return coordinates.every(item =>
                this.$refs.map.mapObject.getBounds().contains(item)
            )
        },
        alignMap(bounds) {
            if (
                bounds?.length &&
                (this.$refs.map.mapObject.getZoom() < 12 ||
                    !this.areCoordinatesVisible(bounds))
            ) {
                this.$nextTick(
                    debounce(() => this.$refs.map.fitBounds(bounds), 100)
                )
            }
        },
        disablePositionTracking() {
            this.$refs.map.mapObject.stopLocate()
            this.setPositionTrackingEnabled(false)
        },
        onLocationFound({ latitude, longitude }) {
            this.setUserPosition({
                lat: latitude,
                lng: longitude,
            })
        },
        onLocationError() {
            this.disablePositionTracking()
        },
        zoomUpdated(zoom) {
            this.zoom = zoom
        },
    },
}
</script>

<style lang="scss">
.editing {
    path.leaflet-interactive {
        cursor: grab;
    }

    .leaflet-marker-draggable {
        width: 14px !important;
        height: 14px !important;
        margin-left: -7px !important;
        margin-top: -7px !important;
        border-radius: 2px;
        cursor: crosshair;
    }
}

.leaflet-popup-content-wrapper {
    border-radius: 4px;
}
</style>
