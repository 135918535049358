<template>
    <div class="side-panel">
        <NavigationBar
            :collapsed="collapsed"
            :showarrowright="isChartViewActive"
            class="u-bb"
            @close="$emit('close')"
            @collapse="collapsed = true"
            @expand="collapsed = false"
            @navigateback="navigateBack"
        />

        <transition name="dropdown">
            <div v-show="!collapsed" class="side-panel__content">
                <transition
                    :name="transitionName"
                    mode="out-in"
                    @beforeLeave="beforeLeave"
                    @enter="enter"
                    @afterEnter="afterEnter"
                >
                    <router-view @hide="$emit('hide')" />
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import NavigationBar from './NavigationBar'

export default {
    name: 'SidePanel',
    components: {
        NavigationBar,
    },
    data() {
        return {
            prevHeight: 0,
            transitionName: 'fade',
            collapsed: false,
            isChartViewActive: false,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.isChartViewActive = to.meta.isChart
            },
        },
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            let transitionName =
                to.meta.transitionName || from.meta.transitionName

            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

            this.transitionName = transitionName || 'fade'

            this.collapsed = false
            next()
        })
    },
    methods: {
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height
        },
        enter(element) {
            const { height } = getComputedStyle(element)

            element.style.height = this.prevHeight

            setTimeout(() => {
                element.style.height = height
            })
        },
        afterEnter(element) {
            element.style.height = 'auto'
        },
        navigateBack() {
            const fragments = this.$route.path.split('/')
            let last = fragments.pop()

            if (this.$route.params.backRouteName) {
                this.$router.push({ name: this.$route.params.backRouteName })
                return
            }

            if (
                this.$route.meta.isChart ||
                this.$route.meta.isMaintenancePolicy
            ) {
                last = fragments.pop()
            }

            if (last.length === 0) {
                fragments.pop()
            }

            this.collapsed = false
            this.$router.push(fragments.join('/'))
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.side-panel {
    transition: width ease 1s;
    width: 375px;
    display: flex;
    flex-direction: column;
    background: white;
    right: 0;
    -webkit-font-smoothing: antialiased;

    & > div:first-child {
        flex: 0 0 64px;
    }

    @include respond-to('for-small-mobile-only') {
        width: calc(100vw - 2rem);
    }
}

.side-panel__content {
    overflow-x: hidden;
    overflow-y: auto;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition-duration: 0.25s;
    transition-property: transform, opacity, height;

    overflow: hidden;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter,
.slide-right-leave-active {
    transform: translate(2em, 0);
    opacity: 0;
}

.slide-left-leave-active,
.slide-right-enter {
    transform: translate(-2em, 0);
    opacity: 0;
}

.dropdown {
    &-enter,
    &-leave-to {
        max-height: 0;
        opacity: 0;
    }

    &-leave,
    &-enter-to {
        max-height: 1000px;
        opacity: 1;
    }

    &-enter-active,
    &-leave-active {
        transition: all 0.2s linear;
    }
}
</style>
